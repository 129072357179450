import { Box, Typography } from "@mui/material";

import { t } from "i18next";
import { useLocation } from "react-router-dom";
import {
  StyledLandingBackgroundColumn,
  StyledLandingComponent,
  StyledMainContentLanding,
} from "../styles/Landing.styles";

type LandingListItemProps = {
  heading: string;
  icon: string;
  text?: string;
};

export type TemporaryUserProps = {
  username: string;
  temporaryPassword: string;
};

export default function Landing() {
  const location = useLocation();

  const registerPaths = ["/signup", "/confirm-signup", "/admin-confirmation"];

  const listItems: LandingListItemProps[] = registerPaths.includes(
    location.pathname
  )
    ? [
        {
          heading: t("14tagekostenlos"),
          icon: "euroSymbolLanding",
        },
        {
          heading: t("noInstallation"),
          icon: "browser",
        },
        {
          heading: t("simpleStart"),
          icon: "stars",
        },
      ]
    : [
        {
          heading: t("aiAutomation"),
          text: t("aiAutomationDescription"),
          icon: "code",
        },
        {
          heading: t("humanInTheLoop"),
          text: t("humanInTheLoopDescription"),
          icon: "human",
        },
        {
          heading: t("dataPrivacy"),
          text: t("madeInGermany"),
          icon: "landingShield",
        },
      ];

  function LandingListItem({ item }: { item: LandingListItemProps }) {
    return (
      <Box
        sx={{ display: "flex", gap: "1rem", alignItems: "start" }}
        key={item.icon}
      >
        <Box
          component="img"
          src={`/svg/${item.icon}.svg`}
          sx={{ width: "3rem", height: "3rem" }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "0.25rem",
          }}
        >
          <Typography
            sx={{
              marginTop: "9px",
              fontSize: "1.25rem",
              lineHeight: "1.875rem",
              fontWeight: "600",
            }}
          >
            {item.heading}
          </Typography>
          {item.text && <Typography>{item.text}</Typography>}
        </Box>
      </Box>
    );
  }

  return (
    <StyledLandingComponent>
      <StyledLandingBackgroundColumn>
        <StyledMainContentLanding>
          <Typography
            variant="h1"
            sx={{
              fontSize: "3.75rem",
              fontWeight: "500",
              maxWidth: "23rem",
              lineHeight: "4.5rem",
              marginBottom: "2rem",
            }}
          >
            Hallo Adiu. Adieu Doku.
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              height: "16.6rem",
            }}
          >
            {listItems.map((item, idx) => (
              <LandingListItem item={item} key={idx} />
            ))}
          </Box>
        </StyledMainContentLanding>
      </StyledLandingBackgroundColumn>
      {/* <StyledLandingLoginColumn>
        <Box
          component="img"
          src="/svg/Adiu_Logo.svg"
          sx={(theme) => ({
            maxWidth: "120px",
            cursor: "pointer",
            [theme.breakpoints.down("lg")]: {
              marginBottom: "1rem",
              alignSelf: "center",
            },
            [theme.breakpoints.down("sm")]: {
              alignSelf: "start",
              marginLeft: "2rem",
            },
          })}
          onClick={() => {
            window.location.href = "https://www.adiu-health.de/";
          }}
        />
        <StyledLoginArea>
          <AuthRouting />
        </StyledLoginArea>

        <Box
          sx={(theme) => ({
            marginLeft: "2rem",
            [theme.breakpoints.down("lg")]: {
              marginLeft: "0",
              maxWidth: "18rem",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              alignSelf: "center",
              marginTop: "3rem",
            },
          })}
        >
          <Typography>{t("imprint")}</Typography>
          <Typography>
            <Box
              component="span"
              sx={{
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() => {
                window.location.href = "https://www.adiu-health.de/impressum";
              }}
            >
              {t("impressum")}
            </Box>{" "}
            -{" "}
            <Box
              component="span"
              sx={{
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() => {
                window.location.href = "https://www.adiu-health.de/datenschutz";
              }}
            >
              {t("dataProtectionAgreement")}
            </Box>{" "}
            -{" "}
            <Box
              component="span"
              sx={{
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() => {
                window.location.href = "https://www.adiu-health.de/agb";
              }}
            >
              {t("agb")}
            </Box>
          </Typography>
        </Box>
      </StyledLandingLoginColumn> */}
    </StyledLandingComponent>
  );
}
