import { Box, Collapse, Stack, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import { Doctor } from "../../customTypes";
import { DEFAULT_TRANSITION } from "../../utils";
import useData from "../utils/useData";

export default function AdminBillingDashboard() {
  const { data: doctors } = useData<Doctor[]>("allDoctors");
  const [showPromptCompose, setShowPromptCompose] = useState(false);
  const [currentMonthlyIncome, setCurrentMonthlyIncome] = useState(0);
  useMemo(() => {
    if (doctors) {
      setCurrentMonthlyIncome(
        doctors.reduce((acc, doctor) => {
          if (!doctor.license?.stripeSubscriptionId) {
            return acc;
          }
          if (doctor.license?.type === "yearly") {
            return acc + 100;
          } else if (doctor.license?.type === "monthly") {
            return acc + 150;
          }
          return acc;
        }, 0)
      );
    }
  }, [doctors]);
  console.log(currentMonthlyIncome);
  return (
    <Box
      sx={{
        marginTop: "2rem",
        marginBottom: "2rem",
      }}
    >
      <Stack
        onClick={() => {
          setShowPromptCompose((prev) => !prev);
        }}
        sx={{
          flexDirection: "row",
          gap: "1rem",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <Typography
          sx={{
            fontSize: "1rem",
            fontWeight: "bold",
          }}
        >
          Billing
        </Typography>
        <Box
          component="img"
          src="/svg/chevron.svg"
          sx={{
            width: "1.25rem",
            height: "1.25rem",
            ...DEFAULT_TRANSITION,
            transform: showPromptCompose ? "scaleY(-1)" : "none",
          }}
        />
      </Stack>
      <Collapse in={showPromptCompose}>
        <Stack
          sx={{
            marginTop: "2rem",
            flexDirection: "row",
            marginBottom: "1rem",
          }}
        >
          <Typography>
            Current monthly income for this environment ${currentMonthlyIncome}
          </Typography>
        </Stack>
        <Stack
          sx={{
            gap: "1rem",
          }}
        >
          <Typography>Monthly cost of running the platform:</Typography>
          <Stack>
            <Typography>Constant:</Typography>
            <Stack>
              <Typography>Stage env - $50</Typography>
              <Typography>Production env - $450</Typography>
            </Stack>
          </Stack>
          <Stack>
            <Typography>Variable:</Typography>
            <Stack>
              <Typography>+/- $10 per user</Typography>
            </Stack>
          </Stack>
        </Stack>
      </Collapse>
    </Box>
  );
}
