import styled from "@emotion/styled";
import { Box, Button, Theme } from "@mui/material";
import { DEFAULT_TRANSITION } from "../utils";

type Props = {
  theme?: Theme;
};

const boxShadow = "0 2px 6px rgba(41, 48, 86, .12)";
const borderRadius = "40px";

const commonButtonStyles = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: borderRadius,
  cursor: "pointer",
  height: "2.25rem",
  padding: "0 1rem",
  transition: "color .5s, background-color .5s ease-in-out",
};

export const BlueButton = styled(Button)(({ theme }: Props) => ({
  ...commonButtonStyles,
  boxShadow: boxShadow,
  backgroundColor: theme?.palette.primary.main,
  "&:hover": {
    backgroundColor: theme?.palette.primary[700],
    boxShadow:
      "inset 0 1px 4px rgba(41, 48, 86, .12), 0 0 6px 2px rgba(178, 221, 255, .6)",
  },
  "&.Mui-disabled": {
    background: theme?.palette.grey[300],
    color: "white",
  },
}));

export const DarkGreyButton = styled(Button)(({ theme }: Props) => ({
  ...commonButtonStyles,
  boxShadow: boxShadow,
  backgroundColor: theme?.palette.grey[300],
  color: "white",
  "&:hover": {
    color: theme?.palette.text.primary,
    backgroundColor: theme?.palette.primary.main,
  },
}));

export const GreyButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "isCurrentlySelected",
})<{ isCurrentlySelected?: boolean }>(({ theme, isCurrentlySelected }) => ({
  ...commonButtonStyles,
  boxShadow: boxShadow,
  color: isCurrentlySelected
    ? (theme as Theme)?.palette.primary.main
    : (theme as Theme)?.palette.text.primary,
  backgroundColor: isCurrentlySelected
    ? "white"
    : (theme as Theme)?.palette.grey[100],
  "&:hover": {
    backgroundColor: "white",
    color: (theme as Theme)?.palette.primary.main,
  },
}));

export const TransparentButton = styled(Button)(({ theme }: Props) => ({
  ...commonButtonStyles,
  border: `0.5px solid ${theme?.palette.grey[300]}`,
  transition: "all .5s cubic-bezier(.47, 0, .745, .715)",
  boxShadow: "0 2px 6px rgba(185, 192, 212, .32)",
  color: theme?.palette.grey[300],
  "&:hover": {
    transition: "all .5s cubic-bezier(.47, 0, .745, .715)",
    color: theme?.palette.primary.main,
    border: `0.5px solid ${theme?.palette.primary.main}`,
    boxShadow:
      "inset 0 1px 4px rgba(41, 48, 86, .12), 0 0 6px 2px rgba(132, 202, 255, .32)",
  },
  "&.Mui-disabled": {
    color: theme?.palette.grey[300],
    border: `0.5px solid ${theme?.palette.grey[300]}`,
  },
}));

export const DarkTransparentButton = styled(Button)(({ theme }: Props) => ({
  ...commonButtonStyles,
  border: `0.5px solid ${theme?.palette.text.primary}`,
  transition: "all .5s cubic-bezier(.47, 0, .745, .715)",
  boxShadow: boxShadow,
  color: theme?.palette.text.primary,
  "&:hover": {
    transition: "all .5s cubic-bezier(.47, 0, .745, .715)",
    color: theme?.palette.grey[300],
    border: `0.5px solid ${theme?.palette.grey[300]}`,
  },
  "&.Mui-disabled": {
    color: theme?.palette.grey[300],
    border: `0.5px solid ${theme?.palette.grey[300]}`,
  },
}));

export const TransparentButtonAsBox = styled(Box)(({ theme }: Props) => ({
  ...commonButtonStyles,
  border: `0.5px solid ${theme?.palette.grey[400]}`,
  color: theme?.palette.grey[400],
  "&:hover": {
    color: theme?.palette.grey[300],
    border: `0.5px solid ${theme?.palette.grey[300]}`,
  },
}));

export const WhiteButton = styled(Button)(({ theme }: Props) => ({
  ...commonButtonStyles,
  color: theme?.palette.text.primary,
  backgroundColor: "white",
  fontWeight: "600",
  "&:hover": {
    backgroundColor: theme?.palette.primary.light,
  },
}));

export const RedButton = styled(Button)(({ theme }: Props) => ({
  ...commonButtonStyles,
  color: theme?.palette.error.main,
  border: `0.5px solid ${theme?.palette.error.main}`,
  fontWeight: "600",
  "&:hover": {
    backgroundColor: theme?.palette.error.main,
    color: "white",
  },
}));

export const SidebarButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "currentlySelected",
})<{ currentlySelected: boolean }>(({ theme, currentlySelected }) => ({
  display: "flex",
  alignItems: "center",
  gap: "0.5rem",
  cursor: "pointer",
  width: "100%",
  height: "2.25rem",
  boxShadow: "none",
  borderRadius: borderRadius,
  justifyContent: "start",
  backgroundColor: currentlySelected
    ? (theme as Theme).palette.primary.main
    : "none",
  ...DEFAULT_TRANSITION,
  "&:hover": {
    backgroundColor: (theme as Theme).palette.primary[700],
  },
}));

export const TableNavigationButton = styled(Button)(({ theme }: Props) => ({
  ...commonButtonStyles,
  border: `0.5px solid ${theme?.palette.grey[300]}`,
  "&:hover": {
    backgroundColor: theme?.palette.grey[400],
  },
  "&.Mui-disabled": {
    border: `0.5px solid ${theme?.palette.grey[300]}`,
  },
}));
