import { Box, Divider, Typography, useTheme } from "@mui/material";
import { t } from "i18next";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Consultation, Preset } from "../../../customTypes";
import { BlueButton, TransparentButton } from "../../../styles/Buttons.styles";
import {
  ANAMNESIS,
  DEFAULT_BOX_SHADOW,
  isTranscribeOrAudioPresent,
} from "../../../utils";
import { ConsultationContext } from "../../context/ConsultationContext";
import PrettyDropdown from "../../custom/PrettyDropdown";
import AdditionalPatientInformation from "../AdditionalPatientInformation";
import { usePresets } from "../custom/usePresets";
import SwitchableCardTopping from "../utils/SwitchableCardTopping";
import UpdateableField from "../utils/UpdateableField";
import RecordAudioContent from "./RecordAudioContent";
import UploadAudioContent from "./UploadAudioContent";
import UploadTextContent from "./UploadTextContent";
import ConsentNote from "../ConsentNote";

export default function ConsultationRecording() {
  const { consultation, update, createSummary } =
    useContext(ConsultationContext);
  const [type, setType] = useState<string>("RECORD_AUDIO");
  const theme = useTheme();
  const navigate = useNavigate();
  const [currentPresetType, setCurrentPresetType] = useState<
    Consultation["presetType"]
  >(consultation?.presetType || ANAMNESIS);
  const [presets, setPresets] = useState<Preset[]>([]);
  const { getPresets } = usePresets(setPresets, currentPresetType);

  const changeCurrentPreset = (type: Consultation["presetType"]) => {
    setCurrentPresetType(type);
    update({ presetType: type });
  };

  useEffect(() => {
    getPresets();
    update({ presetType: consultation?.presetType || ANAMNESIS });
  }, []);

  const renderType = () => {
    switch (type) {
      case "RECORD_AUDIO":
        return <RecordAudioContent />;
      case "UPLOAD_AUDIO":
        return <UploadAudioContent />;
      case "UPLOAD_TEXT":
        return <UploadTextContent />;
    }
  };

  return !consultation || presets.length === 0 ? (
    <></>
  ) : (
    <>
      <Box sx={{ display: "flex", alignItems: "end" }}>
        <SwitchableCardTopping
          cardType="RECORD_AUDIO"
          label={t("recordAudio")}
          type={type}
          setType={setType}
        />
        <SwitchableCardTopping
          cardType="UPLOAD_AUDIO"
          label={t("uploadAudio")}
          bothSlim
          type={type}
          setType={setType}
        />
        <SwitchableCardTopping
          cardType="UPLOAD_TEXT"
          label={t("uploadText")}
          bothSlim
          type={type}
          setType={setType}
        />
      </Box>
      <Box
        sx={{
          backgroundColor: theme.palette.secondary.main,
          height: "100%",
          width: "100%",
          padding: "2rem",
          borderRadius: "40px",
          ...DEFAULT_BOX_SHADOW,
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          position: "relative",
        }}
      >
        {/* {consultation && !consultation.patientConsentConfirmed && (
          <ConsentPopUp setIsLoading={setIsLoading} />
        )} */}

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "1rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "1rem",
              alignItems: "center",
            }}
          >
            <UpdateableField
              placeholder="Session name #X"
              name={"consultationName"}
              defaultValue={consultation.consultationName || ""}
              update={update}
              sx={{
                width: "12rem",
                zIndex: "999999",
              }}
            />
            <PrettyDropdown
              currentlySelected={currentPresetType}
              options={presets.map((p) => ({
                value: p.type,
              }))}
              onChange={changeCurrentPreset}
              showToGoToPresetSettings
              append={
                <Typography
                  sx={{
                    color: theme.palette.grey[300],
                    fontSize: "1rem",
                  }}
                >
                  {t("type")}
                </Typography>
              }
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Divider
            sx={{
              width: "calc(100% + 4rem)",
              bgcolor: theme.palette.primary[600],
              borderColor: theme.palette.primary[600],
            }}
          />
        </Box>
        {currentPresetType !== "Custom" && (
          <Box
            sx={{
              width: "calc(60% - 1rem)",
            }}
          >
            <AdditionalPatientInformation showGenderDropdown />
          </Box>
        )}

        <Box
          sx={{
            marginTop: "2rem",
            marginBottom: "2rem",
          }}
        >
          {renderType()}
        </Box>

        <Box
          sx={{
            display: "flex",
            gap: "1rem",
            alignItems: "end",
            justifyContent: "space-between",
          }}
        >
          <ConsentNote />
          <Box
            sx={{
              display: "flex",
              gap: "1rem",
            }}
          >
            <TransparentButton
              sx={{
                height: "3.5rem",
              }}
              onClick={() => navigate("/")}
            >
              {t("cancel")}
            </TransparentButton>
            <BlueButton
              disabled={!isTranscribeOrAudioPresent(consultation)}
              onClick={async () => {
                await createSummary();
              }}
              sx={{
                height: "3.5rem",
              }}
            >
              {currentPresetType === ANAMNESIS
                ? t("createAnamnesis")
                : t("createSummary")}
            </BlueButton>
          </Box>
        </Box>
      </Box>
    </>
  );
}
