import {
  Box,
  CircularProgress,
  Divider,
  Typography,
  useTheme,
} from "@mui/material";
import { t } from "i18next";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { BlueButton, TransparentButton } from "../../../styles/Buttons.styles";
import { DEFAULT_TRANSITION } from "../../../utils";
import Loading from "../../Loading";
import { ConsultationContext } from "../../context/ConsultationContext";
import { UserContext } from "../../context/UserContext";
import AdditionalPatientInformation from "../AdditionalPatientInformation";
import ConsentNote from "../ConsentNote";
import { useConsultation } from "../custom/useConsultation";
import DevSummarization from "../dev/DevSummarization";
import UpdateableField from "../utils/UpdateableField";
import PostActionPopUp from "./PostActionPopUp";

export default function ConsultationSummaryContent({
  setType,
}: {
  setType: Dispatch<SetStateAction<"SUMMARY" | "REGENERATE">>;
}) {
  const theme = useTheme();
  const { update, consultation, isCurrentlyPolling, summarize } =
    useContext(ConsultationContext);
  const { downloadConsultation } = useConsultation();
  const { doctorProfile } = useContext(UserContext);
  const [showActionDone, setShowActionDone] = useState<
    undefined | "COPIED" | "DOWNLOADED" | "SAVED"
  >();
  const [previousAmountOfSummaries, setPreviousAmountOfSummaries] = useState<
    number | undefined
  >(consultation?.summaries?.length);

  const [currentlySelectedSummaryIdx, setCurrentlySelectedSummaryIdx] =
    useState<number>(consultation?.summaries?.length! - 1 || 0);

  useEffect(() => {
    if (consultation?.summarizationError && consultation.summaries?.at(-1)) {
      update({ summarizationError: "" });
    }
    if (
      consultation &&
      consultation.summaries &&
      consultation.summaries.length > previousAmountOfSummaries!
    ) {
      setCurrentlySelectedSummaryIdx(consultation.summaries.length - 1);
      setPreviousAmountOfSummaries(consultation.summaries.length);
    }
  }, [consultation?.summaries]);

  const multipleSummariesCreated =
    consultation && consultation.summaries && consultation.summaries.length > 1;

  return !consultation ? (
    <Loading />
  ) : (
    <>
      {showActionDone && (
        <PostActionPopUp
          setShowActionDone={setShowActionDone}
          showActionDone={showActionDone}
          currentlySelectedSummaryIdx={currentlySelectedSummaryIdx}
        />
      )}
      <Box
        sx={{
          display: "flex",
          marginBottom: "3rem",
          width: "100%",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            height: "6rem",
            gap: "2rem",
          }}
        >
          {consultation.transcriptionError ||
          consultation.summarizationError ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{
                  color: theme.palette.error.main,
                  fontWeight: "600",
                  fontSize: "18px",
                }}
              >
                {consultation.transcriptionError
                  ? `Error generating transcription of the audio recording`
                  : "Error generating summary of the transcription"}
              </Typography>
              <Typography
                sx={{
                  color: theme.palette.error.main,
                  fontSize: "14px",
                }}
              >
                {consultation.transcriptionError
                  ? `${consultation.transcriptionError} - please try again with a new consultation recording`
                  : consultation.summarizationError}
              </Typography>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontWeight: "600",
                    fontSize: "18px",
                  }}
                >
                  {t("summaryOfSummary")}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                  }}
                >
                  {t("summaryOfSummaryDescription")}
                </Typography>
              </Box>
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              gap: "1rem",
              height: "100%",
              alignItems: "center",
            }}
          >
            {isCurrentlyPolling && <CircularProgress />}
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            marginTop: "2rem",
            gap: "2rem",
            justifyContent: "space-between",
            width: "100%",
            minHeight: "30rem",
            height: "100%",
          }}
        >
          <UpdateableField
            rows={20}
            name="summaries"
            update={update}
            defaultValue={
              consultation.summaries?.at(currentlySelectedSummaryIdx) || ""
            }
            currentSummaryIndex={currentlySelectedSummaryIdx}
            consultation={consultation}
            sx={{
              width: "100%",
              "& .MuiInputBase-root": {
                borderRadius: "12px",
                height: "fit-content",
              },
            }}
          />

          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",

              gap: "0.5rem",
            }}
          >
            {consultation.presetType !== "Custom" && (
              <>
                <AdditionalPatientInformation />
                <Divider
                  sx={{
                    margin: "1rem 0",
                    bgcolor: theme.palette.primary.dark,
                  }}
                />
              </>
            )}

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <TransparentButton
                onClick={() => {
                  multipleSummariesCreated
                    ? setType("REGENERATE")
                    : summarize();
                }}
                sx={{
                  width: "fit-content",
                  gap: "0.5rem",
                  "&:hover": {
                    ".MuiBox-root": {
                      filter:
                        "brightness(0) saturate(100%) invert(82%) sepia(7%) saturate(486%) hue-rotate(187deg) brightness(89%) contrast(101%)",
                    },
                  },
                }}
                disabled={!consultation.transcribed}
              >
                <Box
                  component="img"
                  src={`/svg/${
                    multipleSummariesCreated ? "flipBack" : "retry"
                  }.svg`}
                  sx={{
                    ...DEFAULT_TRANSITION,
                  }}
                />
                {multipleSummariesCreated
                  ? t("regenerateSettings")
                  : t("tryAgain")}
              </TransparentButton>

              {consultation.summaries && consultation.summaries.length > 0 && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.5rem",
                  }}
                >
                  <Typography
                    sx={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setCurrentlySelectedSummaryIdx((prev) => {
                        return prev - 1 < 0 ? 0 : prev - 1;
                      });
                    }}
                  >
                    {"<"}
                  </Typography>
                  <Typography>
                    {`${currentlySelectedSummaryIdx + 1}/${
                      consultation.summaries.length
                    }`}
                  </Typography>
                  <Typography
                    sx={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setCurrentlySelectedSummaryIdx((prev) => {
                        if (consultation && consultation.summaries) {
                          return prev + 1 > consultation.summaries.length - 1
                            ? prev
                            : prev + 1;
                        } else {
                          return prev;
                        }
                      });
                    }}
                  >
                    {">"}
                  </Typography>
                </Box>
              )}
            </Box>

            {multipleSummariesCreated && (
              <Typography
                sx={{
                  marginTop: "1rem",
                }}
              >
                {t("regenerateSettingsDescription")}
              </Typography>
            )}
            <Box
              sx={(theme) => ({
                marginLeft: "auto",
                marginTop: "auto",
                display: "flex",
                gap: "1rem",
                [theme.breakpoints.down("lg")]: {
                  flexDirection: "column",
                  alignItems: "end",
                },
              })}
            >
              <TransparentButton
                sx={{}}
                onClick={() => {
                  setShowActionDone("SAVED");
                }}
              >
                {t("saveAndContinueLater")}
              </TransparentButton>
              <BlueButton
                sx={{
                  gap: "0.5rem",
                }}
                onClick={() => {
                  downloadConsultation(consultation);
                  setShowActionDone("DOWNLOADED");
                }}
              >
                <Box component="img" src="/svg/download.svg" />
                {t("downloadText")}
              </BlueButton>
              <BlueButton
                sx={{
                  gap: "0.5rem",
                }}
                onClick={() => {
                  navigator.clipboard.writeText(
                    consultation?.summaries?.at(currentlySelectedSummaryIdx) ||
                      ""
                  );
                  setShowActionDone("COPIED");
                }}
              >
                <Box component="img" src="/svg/copy.svg" />
                {t("copyText")}
              </BlueButton>
            </Box>
          </Box>
        </Box>
        <ConsentNote />
        {doctorProfile?.isDevUser && <DevSummarization />}
      </Box>
    </>
  );
}
