import { Box, Typography } from "@mui/material";
import { t } from "i18next";
import { downloadConsentForm } from "../../utils";

export default function ConsentNote() {
  return (
    <Box>
      <Typography>
        {t("Only record with patient's ")}
        <Box
          sx={{
            fontWeight: "600",
            cursor: "pointer",
          }}
          component={"span"}
          onClick={downloadConsentForm}
        >
          {t("consent")}
        </Box>
        {t(". Always double-check the result.")}
      </Typography>
    </Box>
  );
}
