import { Stack, SxProps, TextField, Theme, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Consultation } from "../../../customTypes";

export default function UpdateableField({
  defaultValue,
  name,
  update,
  sx,
  rows,
  inputPropsSx,
  placeholder,
  multiline = true,
  currentSummaryIndex,
  consultation,
  label,
}: {
  name: string;
  update: (payload: any) => void;
  defaultValue?: string;
  sx?: SxProps<Theme>;
  rows?: number | null;
  inputPropsSx?: SxProps<Theme>;
  placeholder?: string;
  multiline?: boolean;
  currentSummaryIndex?: number;
  consultation?: Consultation;
  label?: string;
}) {
  const [value, setValue] = useState(defaultValue || "");

  const updateOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (name === "summaries") {
      if (consultation && currentSummaryIndex !== undefined) {
        const summaries = consultation.summaries || [];
        summaries[currentSummaryIndex] = e.currentTarget.value;
        update({ summaries: summaries });
      }
    } else {
      update({ [name]: e.currentTarget.value });
    }
  };

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  const updateValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  return (
    <Stack
      gap={"0.5rem"}
      sx={{
        width: "100%",
      }}
    >
      {label && <Typography>{label}</Typography>}
      <TextField
        id={`${name}-input`}
        variant="outlined"
        InputProps={{
          sx: inputPropsSx
            ? inputPropsSx
            : {
                backgroundColor: "white",
                borderRadius: "12px",
              },
        }}
        InputLabelProps={{
          shrink: true,
        }}
        onBlur={updateOnBlur}
        sx={
          sx
            ? sx
            : {
                width: "100%",
              }
        }
        value={value}
        onChange={updateValue}
        multiline={multiline}
        rows={rows || undefined}
        placeholder={placeholder}
      />
    </Stack>
  );
}
