import { Stack } from "@mui/material";
import { sendRequest } from "../../Api";
import PrettyDropdown from "../custom/PrettyDropdown";

export default function UserTypePicker({
  doctorId,
  userType,
  onUserTypeChange,
}) {
  const userTypes = [
    { label: "Paying", value: "paying" },
    { label: "Non-Paying", value: "non-paying" },
    { label: "Developer", value: "developer" },
    { label: "Friend", value: "friend" },
  ];
  return (
    <Stack
      sx={{
        flexDirection: "row",
        width: "15%",
        gap: "2rem",
        alignItems: "center",
      }}
    >
      <PrettyDropdown
        options={userTypes}
        currentlySelected={userType}
        onChange={async (newType) => {
          await sendRequest(
            "api/userType",
            "PUT",
            JSON.stringify({
              doctorId: doctorId,
              userType: newType,
            })
          );
          onUserTypeChange(doctorId, newType);
        }}
      />
    </Stack>
  );
}
