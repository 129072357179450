import { Box, Collapse, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { sendRequest } from "../../Api";
import { PromptCompose } from "../../customTypes";
import { convertToJSON, DEFAULT_TRANSITION } from "../../utils";
import UpdateableField from "../consultation/utils/UpdateableField";

export default function PromptComposeSettings() {
  const [promptComposeItems, setPromptComposeItems] = useState<PromptCompose[]>(
    []
  );
  const [showPromptCompose, setShowPromptCompose] = useState(false);

  useEffect(() => {
    sendRequest("/api/promptCompose", "GET").then((res) => {
      if (!res) return;
      setPromptComposeItems(convertToJSON(res));
    });
  }, []);

  function PromptComposeItem({ item }: { item: PromptCompose }) {
    const updatePromptCompose = (payload: any) => {
      sendRequest(
        "/api/promptCompose",
        "PUT",
        JSON.stringify({
          field: item.field,
          ...payload,
        })
      ).then((res) => {
        if (!res) return;
        const newItem: PromptCompose = convertToJSON(res);
        setPromptComposeItems((prev) =>
          prev.map((p) => (p.field === newItem.field ? newItem : p))
        );
      });
    };

    const customOrder = [
      "intro",
      "additionalInfoIntro",
      "transcriptIntro",
      "typesIntro",
      "customPromptStylingIntro",
      "end",
      "Shorten",
      "Extend",
      "Simplify",
      "Formalise",
      "Narrative",
      "Bullet points",
    ];

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          marginTop: "3rem",
        }}
      >
        <Typography
          sx={{
            fontSize: "1.25rem",
            fontWeight: "600",
          }}
        >
          {item.field}
        </Typography>
        {Object.entries(item)
          .sort(([keyA], [keyB]) => {
            const indexA = customOrder.indexOf(keyA);
            const indexB = customOrder.indexOf(keyB);
            if (indexA === -1) return 1;
            if (indexB === -1) return -1;
            return indexA - indexB;
          })
          .map(([key, value]) => {
            if (key === "field") return null;
            return (
              <Box key={key}>
                <Typography>{key}</Typography>
                <UpdateableField
                  defaultValue={value}
                  name={key}
                  update={updatePromptCompose}
                  rows={key === "intro" ? 10 : 3}
                  sx={{
                    width: "100%",
                    "& .MuiInputBase-root": {
                      borderRadius: "12px",
                      height: "fit-content",
                    },
                  }}
                />
              </Box>
            );
          })}
      </Box>
    );
  }

  return (
    <Box>
      <Stack
        onClick={() => {
          setShowPromptCompose((prev) => !prev);
        }}
        sx={{
          flexDirection: "row",
          marginTop: "2rem",
          gap: "1rem",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <Typography
          sx={{
            fontSize: "1rem",
            fontWeight: "bold",
          }}
        >
          Edit prompt structure
        </Typography>
        <Box
          component="img"
          src="/svg/chevron.svg"
          sx={{
            width: "1.25rem",
            height: "1.25rem",
            ...DEFAULT_TRANSITION,
            transform: showPromptCompose ? "scaleY(-1)" : "none",
          }}
        />
      </Stack>
      <Collapse in={showPromptCompose}>
        {promptComposeItems.map((item, idx) => (
          <PromptComposeItem key={idx} item={item} />
        ))}
      </Collapse>
    </Box>
  );
}
