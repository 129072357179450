import { AmplifyUser } from "@aws-amplify/ui";
import { Box, useTheme } from "@mui/material";
import { Amplify, Auth } from "aws-amplify";
import i18next, { t } from "i18next";
import { useMemo, useState } from "react";
import { initReactI18next } from "react-i18next";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import config from "./aws-exports";
import HelpPage from "./components/HelpPage";
import Landing from "./components/Landing";
import Sidebar from "./components/Sidebar";
import TopNotification from "./components/TopNotification";
import ConsultationPage from "./components/consultation/ConsultationPage";
import MobileConsultationRecording from "./components/consultation/MobileConsultationRecording";
import { UserContext } from "./components/context/UserContext";
import Dashboard from "./components/dashboard/Dashboard";
import OpenCases from "./components/dashboard/OpenCases";
import Overview from "./components/dashboard/overview/Overview";
import Presets from "./components/presets/Presets";
import Settings from "./components/settings/Settings";
import { Doctor } from "./customTypes";
import translations from "./translations.json";
Amplify.configure(config);

export default function App() {
  const [user, setUser] = useState<AmplifyUser>();
  const [doctorProfile, setDoctorProfile] = useState<Doctor>();
  const [avatar, setAvatar] = useState<string>();
  const [isLoading, setLoading] = useState(true);
  const theme = useTheme();

  i18next.use(initReactI18next).init({
    resources: translations,
    lng: localStorage.getItem("language") || "de",
    interpolation: {
      escapeValue: false,
    },
  });

  const value = {
    user,
    setUser,
    doctorProfile,
    setDoctorProfile,
    avatar,
    setAvatar,
  };

  const getCurrentAuthenticatedUser = async () => {
    const user = await Auth.currentAuthenticatedUser()
      .then((response: AmplifyUser) => {
        setLoading(false);
        return response;
      })
      .catch(() => setLoading(false));
    if (user) {
      setUser(user);
    }
  };

  useMemo(() => {
    getCurrentAuthenticatedUser();
  }, []);

  if (isLoading) {
    return <></>;
  }
  if (!user || (doctorProfile && doctorProfile.needsAdminConfirmation)) {
    return (
      <BrowserRouter>
        <UserContext.Provider value={value}>
          <Landing />
        </UserContext.Provider>
      </BrowserRouter>
    );
  }
  if (window.location.href.includes("mobil")) {
    return (
      <BrowserRouter>
        <UserContext.Provider value={value}>
          <MobileConsultationRecording />
        </UserContext.Provider>
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
      <UserContext.Provider value={value}>
        <Box
          sx={{
            display: "flex",
            height: "100%",
          }}
        >
          <Sidebar />
          <Box
            sx={{
              height: "100%",
              paddingTop: "1.5rem",
              width: "100%",
              maxWidth: "calc(100% - 17.5rem)",
              backgroundColor: theme.palette.secondary.main,
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                height: "100%",
                width: "100%",
                backgroundColor: "white",
                borderRadius: "40px 0 0 0",
              }}
            >
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  padding: "2rem",
                  flexDirection: "column",
                  background:
                    "linear-gradient(90deg, rgba(255,255,255,0.5) 0%, rgba(231,242,250,0.5) 50%, rgba(231,242,250,0.5) 100%)",
                  borderRadius: "40px 0 0 0",
                  overflowY: "auto",
                  scrollbarWidth: "none",
                  "::-webkit-scrollbar": {
                    display: "none",
                  },
                }}
              >
                <TopNotification />
                <Routes>
                  <Route
                    path="/"
                    element={
                      <Dashboard
                        content={<Overview />}
                        title={t("dashboard")}
                      />
                    }
                  />
                  <Route
                    path="open"
                    element={
                      <Dashboard
                        content={<OpenCases />}
                        title={t("openCases")}
                      />
                    }
                  />
                  <Route path="help" element={<HelpPage />} />
                  <Route
                    path="presets"
                    element={
                      <Dashboard content={<Presets />} title={t("presets")} />
                    }
                  />
                  <Route
                    path="consultation/:createdTimestamp"
                    element={<ConsultationPage />}
                  />
                  <Route path="consultation/" element={<ConsultationPage />} />
                  <Route path="settings" element={<Settings />} />
                  <Route
                    path="*"
                    element={
                      <Dashboard
                        content={<Overview />}
                        title={t("dashboard")}
                      />
                    }
                  />
                </Routes>
              </Box>
            </Box>
          </Box>
        </Box>
      </UserContext.Provider>
    </BrowserRouter>
  );
}
