import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { useState } from "react";
import { sendRequest } from "../../Api";

export default function TrialExtensionDatePicker({ defaultDate, doctorId }) {
  const [value, setValue] = useState<Dayjs | null>(dayjs.unix(defaultDate));
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"de-DE"}>
      <DatePicker
        value={value}
        onChange={async (newValue) => {
          setValue(newValue);
          await sendRequest(
            "api/doctorExtendTrial",
            "PUT",
            JSON.stringify({
              doctorId: doctorId,
              newTrialExpiryTimestamp: newValue?.unix(),
            })
          );
        }}
        format="DD.MM.YYYY"
      />
    </LocalizationProvider>
  );
}
