import { Stack, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { sendRequest } from "../../Api";
import { Doctor } from "../../customTypes";
import UpdateableField from "../consultation/utils/UpdateableField";
import FullLengthDivider from "../custom/FullLengthDivider";
import PrettyDropdown, { Option } from "../custom/PrettyDropdown";
import useData from "../utils/useData";

export default function AdminSetDoctorCustomPrompt() {
  const theme = useTheme();
  const { data: doctors } = useData<Doctor[]>("allDoctors");
  const [currentlySelectedDoctorId, setCurrentlySelectedDoctorId] = useState<
    string | ""
  >();
  const { data: currentlySelectedDoctorCustomPreset } = useData<any>(
    `doctorCustomPreset?doctorId=${currentlySelectedDoctorId}`
  );
  const options: Option[] =
    doctors?.map((doctor) => ({
      label: doctor.email,
      value: doctor.id,
    })) || [];

  return (
    <Stack gap={"1rem"}>
      <FullLengthDivider color={theme.palette.grey[300]} />
      <Typography>Custom prompt per user</Typography>
      <PrettyDropdown
        width="25rem"
        options={options}
        onChange={(value) => {
          setCurrentlySelectedDoctorId(value);
        }}
      />
      <UpdateableField
        name="customPrompt"
        multiline
        rows={5}
        update={async (newVal) =>
          await sendRequest(
            "api/doctorCustomPreset",
            "PUT",
            JSON.stringify({
              doctorId: currentlySelectedDoctorCustomPreset.doctorId,
              createdTimestamp:
                currentlySelectedDoctorCustomPreset.createdTimestamp,
              prompt: newVal.customPrompt,
            })
          )
        }
        defaultValue={currentlySelectedDoctorCustomPreset?.prompt || ""}
        sx={{
          width: "100%",
          "& .MuiInputBase-root": {
            borderRadius: "12px",
            height: "fit-content",
          },
        }}
      />
      <FullLengthDivider color={theme.palette.grey[300]} />
    </Stack>
  );
}
