import { Stack } from "@mui/material";
import { sendRequest } from "../../Api";
import PrettyDropdown from "../custom/PrettyDropdown";

export default function UserStatusPicker({
  doctorId,
  userStatus,
  onUserStatusChange,
}) {
  const userStatuses = [
    { label: "Active", value: "active" },
    { label: "Inactive", value: "inactive" },
    { label: "Cancelled", value: "cancelled" },
  ];
  return (
    <Stack
      sx={{
        flexDirection: "row",
        width: "15%",
        gap: "2rem",
        alignItems: "center",
      }}
    >
      <PrettyDropdown
        options={userStatuses}
        currentlySelected={userStatus}
        onChange={async (newStatus) => {
          await sendRequest(
            "api/userStatus",
            "PUT",
            JSON.stringify({
              doctorId: doctorId,
              userStatus: newStatus,
            })
          );
          onUserStatusChange(doctorId, newStatus);
        }}
      />
    </Stack>
  );
}
