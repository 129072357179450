import { Box, TextField, Typography, useTheme } from "@mui/material";
import { useFormik } from "formik";
import { t } from "i18next";
import { Dispatch, SetStateAction, useContext, useState } from "react";
import { sendRequest } from "../../Api";
import { Preset } from "../../customTypes";
import { BlueButton, TransparentButton } from "../../styles/Buttons.styles";
import { checkUserProfileDev } from "../../utils";
import { UserContext } from "../context/UserContext";
import PrettyDropdown from "../custom/PrettyDropdown";
import PresetSelects from "./PresetSelects";

export type Values = {
  tone?: string;
  length?: string;
  format?: string;
  customPromptStyling?: string;
  prompt?: string;
};

const allowedCustomPromptEditingUsers = [
  "florian.rasche@googlemail.com",
  "maksymilian.kalek+1234@perelyn.com",
];

export default function PresetsForm({
  currentlySelectedPreset,
  getPresets,
  isSettings,
  presets,
  setType,
}: {
  currentlySelectedPreset: Preset;
  getPresets: () => void;
  isSettings?: boolean;
  presets?: Preset[];
  setType?: Dispatch<SetStateAction<string>>;
}) {
  const theme = useTheme();
  const [somethingChanged, setSomethingChanged] = useState(false);
  const { user, doctorProfile } = useContext(UserContext);

  const submit = async (values: Values) => {
    try {
      await sendRequest(
        "api/preset",
        "PUT",
        JSON.stringify({
          createdTimestamp: currentlySelectedPreset.createdTimestamp,
          type: currentlySelectedPreset.type,
          ...values,
        })
      );
      getPresets();
      setSomethingChanged(false);
    } catch (error) {}
  };

  const initialValues: Values = {
    tone: currentlySelectedPreset.tone || "Formalise",
    length: currentlySelectedPreset.length || "Shorten",
    format: currentlySelectedPreset.format || "Narrative",
    customPromptStyling: currentlySelectedPreset.customPromptStyling || "",
    prompt: currentlySelectedPreset.prompt || "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: submit,
    enableReinitialize: true,
  });

  const isDisallowedToEdit =
    !checkUserProfileDev(user) &&
    currentlySelectedPreset.type === "Custom" &&
    !allowedCustomPromptEditingUsers.includes(doctorProfile?.email || "");
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        position: "relative",
      }}
      component="form"
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {isSettings && presets && setType ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "0.75rem",
            }}
          >
            <Typography
              sx={{
                fontSize: "1.125rem",
                fontWeight: "600",
              }}
            >
              {t("preSettingsFor")}
            </Typography>
            <PrettyDropdown
              currentlySelected={currentlySelectedPreset.type}
              options={presets.map((p) => ({
                value: p.type,
              }))}
              onChange={setType}
            />
          </Box>
        ) : (
          <Typography>{t("presetsDescription")}</Typography>
        )}

        <Box
          sx={{
            display: "flex",
            gap: "1rem",
          }}
        >
          <TransparentButton
            onClick={(e) => {
              formik.handleReset(e);
            }}
            disabled={!somethingChanged}
          >
            {t("cancel")}
          </TransparentButton>
          <BlueButton
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              formik.handleSubmit();
            }}
            disabled={!somethingChanged || isDisallowedToEdit}
          >
            {t("saveChanges")}
          </BlueButton>
        </Box>
      </Box>
      {isDisallowedToEdit && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Typography
            sx={{
              color: theme.palette.error.main,
            }}
          >
            {t("Currently you can't modify this prompt.")}
          </Typography>
        </Box>
      )}

      {currentlySelectedPreset.type !== "Custom" ? (
        <>
          <PresetSelects
            formik={formik}
            setSomethingChanged={setSomethingChanged}
            currentPreset={currentlySelectedPreset}
          />
          <Typography
            sx={{
              marginTop: "1rem",
            }}
          >
            {t("customPromptStyling")}
          </Typography>
          <TextField
            id="customPromptStyling"
            name="customPromptStyling"
            autoComplete="off"
            variant="outlined"
            InputProps={{
              sx: {
                backgroundColor: "white",
                borderRadius: "12px",
              },
            }}
            sx={{
              width: "100%",
              "& .MuiInputBase-root": {
                borderRadius: "12px",
                borderWidth: "0.5px",
                height: "100%",
              },
            }}
            value={formik.values.customPromptStyling}
            onChange={(e) => {
              setSomethingChanged(true);
              formik.handleChange(e);
            }}
            onBlur={formik.handleBlur}
            error={
              formik.touched.customPromptStyling &&
              Boolean(formik.errors.customPromptStyling)
            }
            multiline
            rows={4}
            placeholder={t("inputYourCustomPromptStylingSuggestion")}
          />
        </>
      ) : (
        <>
          <Typography
            sx={{
              marginTop: "1rem",
            }}
          >
            {t("customPrompt")}
          </Typography>
          <TextField
            id="prompt"
            name="prompt"
            autoComplete="off"
            variant="outlined"
            InputProps={{
              sx: {
                backgroundColor: "white",
                borderRadius: "12px",
              },
            }}
            sx={{
              width: "100%",
              "& .MuiInputBase-root": {
                borderRadius: "12px",
                borderWidth: "0.5px",
                height: "100%",
              },
            }}
            value={formik.values.prompt}
            onChange={(e) => {
              setSomethingChanged(true);
              formik.handleChange(e);
            }}
            onBlur={formik.handleBlur}
            error={formik.touched.prompt && Boolean(formik.errors.prompt)}
            multiline
            rows={16}
            placeholder={t("inputYourCustomPrompt")}
          />
        </>
      )}
    </Box>
  );
}
