import CircleIcon from "@mui/icons-material/Circle";
import {
  Box,
  Stack,
  TextField,
  Typography,
  keyframes,
  useTheme,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import useRecorder from "../custom/useRecorder";
import Timer from "../utils/Timer";

import { sendRequest } from "../../../Api";
import { convertToJSON, convertToMinSec } from "../../../utils";
import Loading from "../../Loading";
import { ConsultationContext } from "../../context/ConsultationContext";
import { UserContext } from "../../context/UserContext";
import FullLengthDivider from "../../custom/FullLengthDivider";
import RecordingButtons from "../RecordingButtons";
import { AudioReactRecorder, RecordState } from "./Waveform";

export default function AudioRecorder({
  update,
  recordingDuration,
}: {
  update: (payload: any) => void;
  recordingDuration?: string;
}) {
  const theme = useTheme();
  const [recordState, setRecordState] = useState<any>(RecordState.NONE);
  const { consultation } = useContext(ConsultationContext);
  const { doctorProfile } = useContext(UserContext);
  const { setIsLoading } = useContext(ConsultationContext);
  const [batchDurationInSeconds, setBatchDurationInSeconds] = useState("90");

  const getDefaultBatchSplit = () => {
    sendRequest("api/defaultBatchSplit", "GET").then((result) => {
      if (!result) return;
      const res = convertToJSON(result);
      setBatchDurationInSeconds(res.value);
    });
  };

  useEffect(() => {
    getDefaultBatchSplit();
  }, []);

  const {
    isRecording,
    startRecording,
    isRecordingPaused,
    resumeRecording,
    pauseRecording,
    stopRecording,
    isUploading,
    time,
    error,
    setError,
  } = useRecorder(update, setIsLoading, null, batchDurationInSeconds);

  const pulse = keyframes`
  0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
`;

  return isUploading ? (
    <Loading />
  ) : (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexBasis: "50%",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "start",
          gap: "0.5rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "2rem",
            }}
          >
            <Box
              component="img"
              src="/svg/microphone.svg"
              sx={{
                width: "3.5rem",
                cursor: "pointer",
              }}
              onClick={() => {
                if (isRecording) {
                  stopRecording();
                } else {
                  startRecording();
                }
              }}
            />
            {recordingDuration && !isRecording && !isUploading ? (
              <Typography
                sx={{
                  fontWeight: "500",
                  fontSize: "22px",
                }}
              >
                {convertToMinSec(recordingDuration)}
              </Typography>
            ) : isUploading ? (
              <Loading />
            ) : isRecording ? (
              <Timer time={time} />
            ) : (
              <Typography
                sx={{
                  fontWeight: "500",
                  fontSize: "22px",
                }}
              >
                {convertToMinSec("0")}
              </Typography>
            )}
          </Box>
          <Stack
            direction={"row"}
            spacing={"2rem"}
            sx={{
              alignItems: "center",
            }}
          >
            {doctorProfile && doctorProfile.isDevUser && (
              <Stack direction={"column"} spacing={"0.25rem"}>
                <Typography>Batch split</Typography>
                <TextField
                  value={batchDurationInSeconds}
                  onChange={(e) => {
                    setBatchDurationInSeconds(e.target.value);
                  }}
                  sx={{
                    width: "7rem",
                  }}
                />
              </Stack>
            )}

            <Box
              sx={{
                display: "flex",
                gap: "0.25rem",
                border: `0.5px solid ${
                  isRecording && !isRecordingPaused
                    ? theme.palette.error.main
                    : theme.palette.secondary.dark
                }`,
                padding: "0.25rem 0.5rem",
                borderRadius: "10px",
                color: theme.palette.secondary.dark,
                alignItems: "center",
                transform: "scale(1)",
                animation:
                  isRecording && !isRecordingPaused
                    ? `${pulse} 1.25s infinite`
                    : "none",
              }}
            >
              <CircleIcon
                sx={{
                  width: "0.4rem",
                  color:
                    isRecording && !isRecordingPaused
                      ? theme.palette.error.main
                      : theme.palette.secondary.dark,
                }}
              />
              <Typography
                sx={{
                  color:
                    isRecording && !isRecordingPaused
                      ? theme.palette.error.main
                      : theme.palette.secondary.dark,
                  fontSize: "85%",
                }}
              >
                Rec
              </Typography>
            </Box>
          </Stack>
        </Box>
      </Box>
      <Box
        sx={{
          display: recordState !== RecordState.START ? "none" : "block",
          marginLeft: "-2rem",
          width: "calc(100% + 4rem)",
        }}
      >
        <AudioReactRecorder
          state={recordState}
          backgroundColor="white"
          foregroundColor={theme.palette.primary.main}
        />
      </Box>
      {isUploading ? (
        <Loading size="2em" />
      ) : recordState !== RecordState.START ? (
        <FullLengthDivider color={theme.palette.primary.main} />
      ) : (
        <></>
      )}

      <Box
        sx={{
          display: "flex",
          flexBasis: "50%",
          justifyContent: "end",
          alignItems: "center",
          flexDirection: "column",
          gap: "0.5rem",
        }}
      >
        {error && (
          <Typography
            sx={{
              color: theme.palette.error.main,
              fontWeight: "600",
              marginBottom: "2rem",
            }}
          >
            {error}
          </Typography>
        )}
        <RecordingButtons
          isRecording={isRecording}
          isRecordingPaused={isRecordingPaused}
          setRecordState={setRecordState}
          pauseRecording={pauseRecording}
          resumeRecording={resumeRecording}
          startRecording={startRecording}
          stopRecording={stopRecording}
          setError={setError}
        />
      </Box>
    </Box>
  );
}
