import { Box, Typography, useTheme } from "@mui/material";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DEFAULT_TRANSITION } from "../../utils";

export type Option = {
  label?: string;
  value: string;
};

export default function PrettyDropdown({
  currentlySelected,
  options,
  onChange,
  showToGoToPresetSettings,
  formikLabel,
  customContent,
  append,
  colorful = false,
  width = "12.5rem",
}: {
  currentlySelected?: string;
  options?: Option[];
  onChange?: (...x: any) => any;
  showToGoToPresetSettings?: boolean;
  formikLabel?: any;
  customContent?: JSX.Element;
  append?: JSX.Element;
  colorful?: boolean;
  width?: string;
}) {
  const navigate = useNavigate();
  const theme = useTheme();
  const [currentlySelectedOption, setCurrentlySelectedOption] = useState<
    string | undefined
  >(currentlySelected);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (currentlySelected) {
      setCurrentlySelectedOption(currentlySelected);
    }
  }, [currentlySelected]);

  function MenuItem({ option }: { option: Option }) {
    const isCurrentlySelected = currentlySelectedOption === option.value;
    const isCurrentlySelectedProps = isCurrentlySelected
      ? { color: theme.palette.grey[300] }
      : {};

    return (
      <Box
        onClick={(e) => {
          setCurrentlySelectedOption(option.value);
          if (onChange) {
            if (formikLabel) {
              (e.currentTarget as any).setAttribute("name", "language");
              onChange(e, option.value);
            } else {
              onChange(option.value);
            }
          }

          setIsOpen(false);
        }}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0 1rem",
          height: "2.25rem",
          borderRadius: "2rem",
          cursor: "pointer",
          ...DEFAULT_TRANSITION,
          "&:hover": isCurrentlySelected
            ? {}
            : {
                backgroundColor: theme.palette.primary[600],
              },
        }}
        id={formikLabel}
      >
        <Typography
          sx={{
            fontSize: "1rem",
            fontWeight: "500",
            whiteSpace: "nowrap",
            ...isCurrentlySelectedProps,
          }}
          id={formikLabel}
        >
          {t(option.label ? option.label : option.value)}
        </Typography>
        {isCurrentlySelected && (
          <Box
            component="img"
            src="/svg/blueCheck.svg"
            sx={{
              marginLeft: "1rem",
              marginRight: "1rem",
              width: "1.25rem",
              height: "1.25rem",
              ...isCurrentlySelectedProps,
            }}
          />
        )}
      </Box>
    );
  }

  const goToSettings = (
    <Box
      sx={{
        display: "flex",
        gap: "0.5rem",
        alignItems: "center",
        padding: "0 0.5rem",
        height: "2.25rem",
        borderRadius: "2rem",
        cursor: "pointer",
        ...DEFAULT_TRANSITION,
        "&:hover": {
          backgroundColor: theme.palette.primary[600],
        },
      }}
      onClick={() => {
        navigate("/presets");
      }}
    >
      <Box component="img" src="/svg/goto.svg" />
      <Typography
        sx={{
          fontSize: "1rem",
          fontWeight: "500",
        }}
      >
        Go to settings
      </Typography>
    </Box>
  );

  const isOpenProps = isOpen
    ? {
        border: "1px solid rgba(0, 0, 0, 0)",
        boxShadow:
          "0px 0px 4px 2px #84CAFF, 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
      }
    : {};

  const isOpenMenuProps = isOpen
    ? {
        opacity: "1",
        marginTop: "0.5rem",
        zIndex: "999999999",
      }
    : {
        opacity: "0",
        marginTop: "-1.5rem",
        zIndex: "-1",
      };

  const getCurrentlySelectedLabel = () => {
    const currentOptionObj = options?.find(
      (o) => o.value === currentlySelectedOption
    );
    return t(
      (currentOptionObj?.label
        ? currentOptionObj.label
        : currentOptionObj?.value) || ""
    );
  };

  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      <Box
        sx={{
          width: customContent ? "100%" : width,
          height: "2.25rem",
          padding: "14px",
          backgroundColor: "white",
          borderRadius: "40px",
          border: `1px solid ${theme.palette.grey[300]}`,
          boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          cursor: "pointer",
          zIndex: "999",
          ...DEFAULT_TRANSITION,
          "&:hover": {
            border: "1px solid rgba(0, 0, 0, 0)",
            boxShadow:
              "0px 0px 4px 2px #84CAFF, 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
          },
          ...isOpenProps,
        }}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <Typography
          sx={{
            fontSize: "1rem",
            color: colorful
              ? theme.palette.primary[700]
              : theme.palette.text.primary,
          }}
        >
          {customContent ? currentlySelected : getCurrentlySelectedLabel()}
        </Typography>
        <Box
          sx={{
            height: "1.25rem",
            display: "flex",
            alignItems: "center",
          }}
        >
          {append}
          <Box
            component="img"
            src="/svg/chevron.svg"
            sx={{
              width: "1.25rem",
              height: "1.25rem",
              ...DEFAULT_TRANSITION,
              transform: isOpen ? "scaleY(-1)" : "none",
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          width: customContent ? "100%" : "fit-content",
          backgroundColor: "white",
          position: "absolute",
          borderRadius: "0.5rem",
          border: `1px solid ${theme.palette.primary[600]}`,
          padding: "0.5rem 0.375rem",
          display: "flex",
          flexDirection: "column",
          transition: "all 100ms linear",
          ...isOpenMenuProps,
        }}
      >
        {customContent}
        {options && options.map((o, idx) => <MenuItem option={o} key={idx} />)}
        {showToGoToPresetSettings && goToSettings}
      </Box>
      {isOpen && (
        <Box
          sx={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            zIndex: "998",
          }}
          onClick={() => setIsOpen(false)}
        />
      )}
    </Box>
  );
}
