import { Box, CircularProgress, useTheme } from "@mui/material";
import { t } from "i18next";
import { useState } from "react";
import { BlueButton } from "../../styles/Buttons.styles";
import { RecordState } from "./recording/Waveform";

export default function RecordingButtons({
  isRecording,
  startRecording,
  isRecordingPaused,
  resumeRecording,
  pauseRecording,
  stopRecording,
  setRecordState,
  setError = (error: string) => {},
}) {
  const start = () => setRecordState(RecordState.START);
  const stop = () => setRecordState(RecordState.STOP);
  const pause = () => setRecordState(RecordState.PAUSE);
  const [stoppedSummary, setStoppedSummary] = useState(false);
  const commonRecordingButtonStyles = {
    display: "flex",
    gap: "0.75rem",
  };
  const theme = useTheme();
  return (
    <>
      {!isRecording ? (
        <BlueButton
          sx={{
            ...commonRecordingButtonStyles,
            "&&": {
              height: "3.75rem",
            },
          }}
          onClick={() => {
            start();
            startRecording();
            setError("");
          }}
        >
          <Box
            component="img"
            src="/svg/recordWaveform.svg"
            sx={{
              width: "1.5rem",
            }}
          />
          {t("recordNow")}
        </BlueButton>
      ) : (
        <Box sx={{ display: "flex", gap: "1em" }}>
          {isRecordingPaused ? (
            <BlueButton
              sx={{
                border: `1px solid ${theme.palette.grey[300]}`,
                ...commonRecordingButtonStyles,
                "&&": {
                  height: "3.75rem",
                  backgroundColor: theme.palette.primary[400],
                },
              }}
              onClick={() => {
                start();
                resumeRecording();
              }}
            >
              <Box
                component="img"
                src="/svg/recordWaveform.svg"
                sx={{
                  width: "1.5rem",
                }}
              />
              {t("resume")}
            </BlueButton>
          ) : (
            <BlueButton
              sx={{
                border: `1px solid ${theme.palette.grey[300]}`,
                ...commonRecordingButtonStyles,
                "&&": {
                  height: "3.75rem",
                  backgroundColor: theme.palette.primary[400],
                },
              }}
              onClick={() => {
                pause();
                pauseRecording();
              }}
            >
              <Box
                component="img"
                src="/svg/pause.svg"
                sx={{
                  width: "1.5rem",
                }}
              />
              {t("pause")}
            </BlueButton>
          )}
          <BlueButton
            sx={{
              ...commonRecordingButtonStyles,
              "&&": {
                height: "3.75rem",
              },
            }}
            onClick={async () => {
              setStoppedSummary(true);
              stop();
              stopRecording();
              setStoppedSummary(false);
            }}
          >
            {stoppedSummary ? (
              <CircularProgress
                size={"1.5rem"}
                style={{ color: theme.palette.text.primary }}
              />
            ) : (
              <Box
                component="img"
                src="/svg/finishRecording.svg"
                sx={{
                  width: "1.5rem",
                }}
              />
            )}

            {t("finish")}
          </BlueButton>
        </Box>
      )}
    </>
  );
}
