import { useEffect, useState } from "react";
import { sendRequest } from "../../Api";
import { convertToJSON } from "../../utils";

export default function useData<T>(requestPath: string) {
  const [data, setData] = useState<T>();

  const fetchData = async () => {
    if (requestPath.includes("undefined")) return;
    return await sendRequest(`api/${requestPath}`, "GET").then((result) => {
      if (!result) return;
      const d = convertToJSON(result);
      setData(d);
    });
  };

  const updateData = (newData: T) => {
    setData(newData);
  };

  useEffect(() => {
    fetchData();
  }, [requestPath]);

  return {
    data,
    fetchData,
    updateData,
  };
}
